@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/mixins/media'
@import '~booksprout-app/src/css/mixins/flex-gap'

.method-selection
  text-align: center

  @include from($breakpoint-md-min)
    .bs-social-login-buttons
      @include gap(30, 'column', true)
      flex-flow: row
      margin-block-end: rem-fn(60)

      .bs-btn
        inline-size: rem-fn(300)

        .q-btn__wrapper
          padding-block: rem-fn(60)

        .q-btn__content
          justify-content: center
          flex-flow: column

        .q-icon
          margin-block-end: rem-fn(40)
          margin-inline-end: 0

        &.bs-facebook .q-icon
          font-size: rem-fn(64) !important

        &.bs-amazon .q-icon
          font-size: rem-fn(70) !important

        &.bs-email .q-icon
          font-size: rem-fn(59) !important
