@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/functions/line-height-fn'
@import '~booksprout-app/src/css/mixins/media'
@import '~booksprout-app/src/css/mixins/flex-gap'

.bs-select-role-buttons
  display: grid
  gap: rem-fn(10)
  grid-template-columns: 1fr 1fr

  @include from($breakpoint-md-min)
    gap: rem-fn(30)
    grid-template-columns: repeat(4, 1fr)

.bs-select-role-btn
  border-color: $bs-light-grey-outline
  border-width: rem-fn(1)
  transition: background-color .1s ease-in-out, border-color .1s ease-out

  &__circle
    align-items: center
    background-color: $bs-light-grey-background
    border-radius: 50%
    block-size: rem-fn(100)
    display: flex
    justify-content: center
    inline-size: rem-fn(100)
    transition: background-color .1s ease-in-out

  .q-btn
    &__wrapper
      padding-block: rem-fn(30)
      padding-inline: rem-fn(25)

      @include from($breakpoint-md-min)
        padding-block: rem-fn(50)

    &__content
      gap: rem-fn(20)
      flex-flow: column
      font-size: rem-fn(16)
      line-height: line-height(22, 16)

      span
        max-inline-size: rem-fn(57)

      @include from($breakpoint-md-min)
        font-size: rem-fn(18)
        line-height: line-height(24, 18)
        gap: rem-fn(30)

        span
          max-inline-size: 100%

  .q-icon
    color: $bs-medium-grey
    transition: color .1s ease-out

  &:hover,
  &:focus,
  &__selected
    background-color: $bs-green-light
    border-color: $bs-green

    .bs-select-role-btn__circle
      background-color: $bs-green

    .q-icon
      color: #fff

.bs-va-role-selected
  margin-block-start: rem-fn(20)

  p
    max-inline-size: 100%
    text-align: center

  .bs-va-role-selected__btn-group
    @include gap(30)
    display: flex
    justify-content: center

  @include from($breakpoint-md-min)
    display: grid
    gap: rem-fn(30)
    grid-template-columns: repeat(4, 1fr)

    p
      grid-column: 4
      margin-block-end: 0 !important
      text-align: left

    .bs-va-role-selected__btn-group
      grid-column: 4

      .bs-btn
        flex: 1 1 50%

.bs-select-va-role-btn
  border-color: $bs-light-grey-outline
  border-width: rem-fn(1)
  color: $bs-medium-grey
  font-size: rem-fn(15)
  font-weight: normal
  line-height: line-height(20, 15)

  .q-btn__wrapper
    padding-block: rem-fn(20)
    padding-inline: rem-fn(12)

  @include from($breakpoint-md-min)
    margin: 0
