@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/functions/line-height-fn'

.bs-signup-email
  .bs-checkbox
    .bs-btn
      vertical-align: baseline

.bs-terms
  margin-block-start: rem-fn(25)

  .bs-btn
    display: inline
    font-size: rem-fn(15)
    font-weight: normal
    line-height: line-height(20, 15)

  .q-field__bottom
    transform: translateY(75%)

